import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"weight\":\"700\",\"subsets\":[\"latin\"],\"variable\":\"--font-space-grotesk\",\"display\":\"swap\"}],\"variableName\":\"spaceGrotesk\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Exo\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-exo\",\"display\":\"swap\"}],\"variableName\":\"exo\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/app/src/app/App.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/app/src/components/footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/assets/index.ts")